import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ApiClient from "lib/ApiClient";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import locale from 'date-fns/locale/ja';
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import saveAs from 'file-saver';
import {format} from "date-fns";
import {Checkbox} from "@mui/material";
import Loading from "components/Loading";

/**
 *
 * @param {number} withdrawalRequestId
 * @param {Date} defaultCompleteDate
 * @param {function} callbackOnSuccess
 * @returns {JSX.Element}
 * @constructor
 */
function Actions({ withdrawalRequestId, defaultCompleteDate, callbackOnSuccess }) {
  const navigate = useNavigate();

  const [completeDialogIsOpen, setCompleteDialogIsOpen] = useState(false);

  const [completeDate, setCompleteDate] = useState(defaultCompleteDate);

  const failButtonClicked = () => {
    ApiClient
      .put(`/secure/admin/withdrawal_requests/${withdrawalRequestId}/fail`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            callbackOnSuccess();
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }

  const completeDateClicked = () => {
    const date = format(completeDate, 'yyyy-MM-dd');

    ApiClient
      .put(`/secure/admin/withdrawal_requests/${withdrawalRequestId}/complete`, {
        date: date
      })
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            callbackOnSuccess();
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button size="small" variant="outlined"
                onClick={() => navigate(`/secure/withdrawal_request/${withdrawalRequestId}`)}>編集</Button>
        <Button size="small" variant="contained"
                onClick={() => setCompleteDialogIsOpen(true)}>振込完了</Button>
        <Button size="small" variant="outlined" color="error"
                onClick={() => failButtonClicked()}>振込失敗</Button>
      </Stack>

      <Dialog open={completeDialogIsOpen}>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <DatePicker label="出金完了日"
                        mask="____/__/__"
                        value={completeDate}
                        onChange={(date) => setCompleteDate(date) }
                        renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCompleteDialogIsOpen(false) }>キャンセル</Button>
          <Button onClick={() => completeDateClicked() }>送信</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default function WithdrawalRequestIndex({setTitle}) {
  const location = useLocation();
  const queryParams = (new URLSearchParams(location.search));

  const [dateForIndex, setDateForIndex] = useState(queryParams.get('date') ? new Date(queryParams.get('date')) : new Date());
  const [requests, setRequests] = useState([]);

  const [batchTargetRequestIds, setBatchTargetRequestIds] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle("出金申請");

    reload();
  }, [dateForIndex]);

  const reload = () => {
    console.log('reloaded');
    const date = format(dateForIndex, 'yyyy-MM-dd');

    ApiClient
      .get(`/secure/admin/withdrawal_requests?target_date=${date}`)
      .then((response) => {
        setRequests(response.data.withdrawalRequests);
      });
  }

  const onDateSelect = (date) => {
    setDateForIndex(date);
    window.location =`/secure/withdrawal_request/?date=${format(date, 'yyyy-MM-dd')}`;
  }

  const zenginCsvDlButtonClicked = () => {
    ApiClient
      .get(`/secure/admin/withdrawal_requests/zengin_csv?target_date=${dateForIndex}`, {responseType: "blob"})
      .then((response) => {
        const mineType = response.headers["content-type"];
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition.substring(contentDisposition.indexOf("''") + 2, contentDisposition.length);
        const blob = new Blob([response.data], {type: mineType});
        saveAs(blob, filename);
      });
  }

  const csvDlButtonClicked = () => {
    ApiClient
      .get(`/secure/admin/withdrawal_requests/csv?target_date=${dateForIndex}`, { responseType: "blob" })
      .then((response) => {
        const mineType = response.headers["content-type"];
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition.substring(contentDisposition.indexOf("''") + 2, contentDisposition.length);
        const blob = new Blob([response.data], { type: mineType });
        saveAs(blob, filename);
      });
  }

  const batchTargetCheckboxChanged = (event, withdrawalRequestId) => {
    if (event.target.checked) {
      setBatchTargetRequestIds([...batchTargetRequestIds, withdrawalRequestId]);
    }
    else {
      setBatchTargetRequestIds(batchTargetRequestIds.filter((id) => id !== withdrawalRequestId));
    }
  }

  const batchTargetAllCheckboxChanged = (event) => {
    if (event.target.checked) {
      setBatchTargetRequestIds(requests.filter((r) => r.state === 'requested').map((r) => r.id));
    }
    else {
      setBatchTargetRequestIds([]);
    }
  }

  const batchCompleteButtonClicked = () => {
    setLoading(true);

    ApiClient
      .put(`/secure/admin/withdrawal_requests/batch_complete`, {
        withdrawal_request_ids: batchTargetRequestIds
      })
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            reload();
            setBatchTargetRequestIds([]);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }

        setLoading(false);
      });
  }

  return (
    <div id="WithdrawalRequestIndex">
      <Loading loading={loading} />
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Stack direction={"row"} spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
              <DatePicker label="出金予定日"
                          mask="____/__/__"
                          value={dateForIndex}
                          onChange={(date) => onDateSelect(date) }
                          renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Button variant={"contained"} onClick={() => csvDlButtonClicked()}>CSVダウンロード</Button>
            <Button variant={"contained"} onClick={() => zenginCsvDlButtonClicked()}>全銀CSVダウンロード</Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Checkbox
                      checked={batchTargetRequestIds.length === requests.filter((r) => r.state === 'requested').length}
                      onChange={(event) => batchTargetAllCheckboxChanged(event)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>ID</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>ステータス</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>ユーザー</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>出金申請額</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>申請日</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>出金予定日</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  requests.map((r) => (
                    <TableRow
                      key={r.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                    >
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          r.state === 'requested' &&
                            <Checkbox
                              checked={batchTargetRequestIds.includes(r.id)}
                              onChange={(event) => batchTargetCheckboxChanged(event, r.id)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                      </TableCell>
                      <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>{r.id}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{r.state}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {r.userDisplayName}（ID：{r.userAccountId}）<br />
                        口座名義：{r.bankAccount.holderName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>{r.grossAmount.toLocaleString()}円</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{r.requestedOn}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{r.scheduledWithdrawalDate}</TableCell>
                      <TableCell>
                        { r.state === 'requested' ? (
                            <Actions withdrawalRequestId={r.id}
                                     defaultCompleteDate={new Date(r.scheduledWithdrawalDate)}
                                     callbackOnSuccess={() => reload()}
                            />
                          ) : (
                            <>-</>
                          )
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} spacing={2}>
            <Button variant={"contained"} onClick={() => batchCompleteButtonClicked()}>チェックした申請を振込完了</Button>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}