import { useEffect, useState } from 'react';
import saveAs from 'file-saver';
import { Stack, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ApiClient from "lib/ApiClient";
import Button from "@mui/material/Button";
import Loading from "components/Loading";
import moment from 'lib/moment_ja';

export default function Index({setTitle}) {
  const [year, setYear] = useState(moment().format('Y'));
  const [month, setMonth] = useState(moment().format('M'));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle('売上報告');
  }, []);

  const downloadButtonClicked = () => {
    const target_month = moment({ year: year, month: parseInt(month) - 1, day: 1 }).format('YYYY-MM-01');

    setLoading(true);

    ApiClient
      .get(`/secure/admin/financial_reports/csv?target_month=${target_month}`, { responseType: "blob" })
      .then((response) => {
        const mineType = response.headers["content-type"];
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition.substring(contentDisposition.indexOf("''") + 2, contentDisposition.length);
        const blob = new Blob([response.data], {type: mineType});
        saveAs(blob, filename);

        setLoading(false);
      });
  }

  return (
    <div id="FinancialReportIndex">
      <Loading loading={loading} />
      <Stack direction={"row"} spacing={2} sx={{ marginTop: "2rem" }}>
        <FormControl>
          <InputLabel id="label-year">年</InputLabel>
          <Select labelId="label-year" id="select-year" label="年" value={year} onChange={(event) => setYear(event.target.value)}>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2024}>2024</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="label-month">月</InputLabel>
          <Select labelId="label-month" id="select-month" label="月" value={month} onChange={(event) => setMonth(event.target.value)}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
          </Select>
        </FormControl>
        <Button variant={"contained"} onClick={() => downloadButtonClicked()}>ダウンロード</Button>
      </Stack>
    </div>
  );
}